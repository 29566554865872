@import url('https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap');
.Adresa .content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #EAE0D9;
    padding: 0px 6%;
}
.Adresa .content h1{
    color: #3B4A6B;
    font-family: 'Sarala', sans-serif;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}
.Adresa .content p {
    color:#3B4A6B;
    font-family: 'Sarala', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}
.Adresa .content div .adresaRiadok .locationIcon{
    width: 20px;
    margin-right: 12.5px;
    margin-left: 2.5px;
}
 .Adresa .content div .adresaRiadok .onlineIcon{
    width: 25px;
    margin-right: 10px;
}
.Adresa .content div{
    align-self: flex-start;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}
.Adresa .content .building{
    height: 260px;
}
.Adresa .content div .adresaRiadok{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
}
.Adresa .content .kdeNasNajdeteMapa{
    height: 260px;
    width: 420px;
    background-color: #EAE0D9;
}
.Adresa .content .kdeNasNajdeteMapa iframe{
    border:0;
    width:100%;
    height:100%;
}
@media (max-width: 1380px){
    .Adresa .content {
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }
    .Adresa .content div{
        margin: auto;
    }
     .Adresa .content .kdeNasNajdeteMapa{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .Adresa .content .building{
        padding: 0 200px;
    } 
}
@media (max-width: 890px){
    .Adresa .content {
        flex-direction: column;
    }
    .Adresa .content .building{
        padding: 0;
    } 
    .Adresa .content h1{
        text-align: center;        
    }
}
@media (max-width: 550px){
    .Adresa .content {
        flex-direction: column;
    }
    .Adresa .content .building{
        width: 100%;
        height: auto;
    } 
    .Adresa .content .kdeNasNajdeteMapa{
        width: 100%;
        aspect-ratio: 1 / 1;
    }
}
@media (min-width: 1600px){
    .Adresa .content{
        max-width: 1610px;
        margin: auto;
    }
}