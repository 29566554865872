body {
  background-color: #EAE0D9;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 2500px; 
  margin: 0 auto !important;
  float: none !important; 
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.active {
  text-shadow:0px 0px 14px rgba(0, 0, 0, 0.2);
  font-weight: bold;
}
html{
  font-size: 16px;
}
h1 { 
  font-size: calc(2rem + 0.8vw);
  margin: 0 0; 
  /* color: yellow !important; */
}
h2 { 
  /* font-size: 1.5rem; */
  font-size: calc(1rem + 0.45vw);
  margin: 0 0;
  /* color: green !important; */
}
h3 { 
  /* font-size: 1.17rem;  */
  font-size: calc(1.1rem + 0.06vw);
  margin: 0 0; 
}
h4 { 
  font-size: calc(0.9rem + 0.1vw); 
  margin: 0 0; 
  /* color:green!important; */
}
p { 
  font-size: calc(1.1rem + 0.03vw);  
  margin: 0 0; 
  /* color:green !important; */
}
ol {
  font-size: calc(1.1rem + 0.03vw);  
}
@media (max-width: 890px){
  h1 { 
    text-align: center !important;
  }
}
@media (max-width: 750px){
  h1 { 
    font-size: calc(1.1rem + 4vw);
    margin: 0 0; 
  }
}
@media(min-width:1800px){
  h1 { 
    font-size: calc(2.0rem + 0.9vw);
  }
  h2 { 
    font-size: calc(1rem + 0.66vw);
  }
  p { 
    font-size: calc(0.55rem + 0.6vw);  
    line-height: calc(1.75rem + 0.6vw) !important;
  }
  li {
    font-size: calc(0.8rem + 0.5vw);
  }
}
