@import url('https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap');
.content{
    padding: 0px 6%;
}
.InstagramFeed{
    display: grid;
    margin: auto;
    grid-template-rows: auto auto;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;         
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}
.IgOdkaz{
    text-decoration: none;
    color: #CAB59A;
    cursor: pointer;
    display:inline-block;  
    transition: 0.2s ease; 
}
.IgOdkaz:hover {
    transform: scale(1.02);
  }
.content h1{
    color: #9AA7B9;
    font-family: 'Sarala', sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}
.InstagramFeed a {
    display: block;
    overflow: hidden;
    aspect-ratio: 1;
    max-width: 420px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.InstagramFeed img,.InstagramFeed video {
    height: 100%;
    margin: auto;
    display: block;
}
@media (min-width: 1600px){
    .content{
        max-width: 1610px;
        margin: auto;
    }
}
@media (max-width: 800px) {
    .InstagramFeed{
        grid-template-columns: repeat(2, 1fr);
    }
}