@import url('https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap');
.Cennik .content {
    background-color: #EAE0D9;
    padding: 0px 6%;
}
.Cennik .content h1 {
    color: #9AA7B9;
    font-family: 'Sarala', sans-serif;
    font-style: normal;
    font-weight: 700;
}
.Cennik .content .miniRiadok .miniPar {
    width:75%;
    margin-right:30px;
    float:left;
}
.Cennik .content .miniRiadok {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 25px;
    border-bottom: 2px solid #CAB59A;
}
.Cennik .content p {
    color:#3B4A6B;
    font-family: 'Sarala', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
}
@media (min-width: 1600px){
    .Cennik .content{
        max-width: 1610px;
        margin: auto;
    }
}