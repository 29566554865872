@import url('https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap');
.NavBar{
    position: relative;
}
.NavBar .menuPozadie .pomocny {
    width:30.5px;
    height:30px;
}
.NavBar .menuPozadie img, .MobileNavigation{
    margin: 10px;
}
.NavBar .MobileNavigation .Hamburger {
    position:absolute;
    margin-left: -20px;
    margin-top:-5px;
    cursor: pointer;
    z-index: 30;
}
.NavBar .MobileNavigation .Hamburger:hover{
    transform: scale(1.08);
    transition: 0.1s;
}
.NavBar nav ul{
    background-color: hsla(25, 29%, 88%, 0.9);
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0px;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    min-width: 450px;
    right: 0px;
    z-index: 10;
    border-radius: 25px 0px 0px 25px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
.NavBar .menuPozadie .MobileNavigation ul{
    list-style-type: none;
    overflow: hidden;
    display: flex;
    font-size: 1.25rem;
}
.NavBar nav ul li{
    text-align: left;
    color: hsla(221, 29%, 33%, 1);
    font-family: 'Sarala', sans-serif;
}
.NavBar .navItem{
    transition: 0.3;
    margin-top: 0.125rem;
    padding-bottom: 0.125rem;
    border-bottom: 0.188rem solid rgba(0, 0, 0, 0);
    border-top: 0.188rem solid rgba(0, 0, 0, 0);
}
.NavBar .menuPozadie{
    top:20vh;
    right: 0px;
    z-index: 100;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: hsla(25, 29%, 88%, 0.7);
    border-radius: 20px 0px 0px 20px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
}
@supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .NavBar .menuPozadie{
        -webkit-backdrop-filter: blur(0.23rem);
        backdrop-filter: blur(0.23rem);     
    }
}
.NavBar .menuPozadie .linkedinIcon{
    width: 35px;
    cursor: pointer;
}
.NavBar .menuPozadie .buttonLink{
    z-index:300;
}
.NavBar .menuPozadie .igIcon{
    width: 50px;
    cursor: pointer;
}
.NavBar .menuPozadie .emccIcon{
    width: 75px;
    cursor: pointer;
}
.NavBar .menuPozadie .linkedinIcon:hover{
    transform: scale(1.1);
    transition: 0.1s;
}
.NavBar .menuPozadie .igIcon:hover{
    transform: scale(1.075);
    transition: 0.1s;
}
.NavBar .menuPozadie .emccIcon:hover{
    transform: scale(1.05);
    transition: 0.1s;
}
.NavBar .navItem:hover{
    cursor: pointer;
    text-shadow:0px 0px 14px rgba(0, 0, 0, 0.2);
    color:hsla(221, 29%, 33%, 1);
    text-decoration: underline;
    font-weight: bold;
}
.NavBar .OutsideZone{
    width:100vw;
    height:200vh;
    background-color: transparent;
    position: absolute;
    right:0;
    top:-50vh;
    z-index: 8;
}
@media (max-width: 1750px){
    .NavBar .menuPozadie img, .MobileNavigation{
        margin: 7px;
    }
}
@media (max-width: 1515px){
    .NavBar .menuPozadie img, .MobileNavigation{
        margin: 5px;
    }    
}

@media (max-width: 1420px){
    .NavBar .menuPozadie img, .MobileNavigation{
        margin: 10px 6px;
    }
    .NavBar .menuPozadie .buttonLink:first-of-type{
        margin-top: 10px;
    }
    .NavBar .menuPozadie .pomocny {
        width:18.3px;
        height:18px;
    }
    .NavBar .menuPozadie .linkedinIcon{
        width: 21px;
    }
    .NavBar .menuPozadie .igIcon{
        width: 30px;
    }
    .NavBar .menuPozadie .emccIcon{
        width: 45px;
    }
}
@media (max-width: 980px){
    .NavBar .menuPozadie img, .MobileNavigation{
        margin: 10px 4px;
    }
}
@media (max-width: 825px){
    .NavBar .menuPozadie{
        top:2vh;
    }
}
@media (max-width: 500px){
    .NavBar nav ul li{
        max-width: 70%;
    }
    .NavBar nav ul{
        min-width: 100vw;
    }
}
