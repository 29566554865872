@import url('https://fonts.googleapis.com/css2?family=Sarala&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Croissant+One&display=swap');
.Tesim .content{
    position:relative;
    background-color: #EAE0D9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem 6%;
}
.Tesim {
    position: relative;
}
.Tesim .content h1{
    color: #CAB59A;
    font-family: 'Croissant One', cursive;
}
.Tesim p {
    margin-top: 80px;
    margin-bottom: 30px;
    color: #3B4A6B;
    font-family: 'Sarala', sans-serif;
    font-style: normal;
    font-weight: 400;
}
.Tesim .logo{
    width:320px;
    height: 104px;
    position: relative;
    z-index: 1;
}
.Tesim .trojuholnikVpravoVpredu{
    position: absolute;
    bottom: 0;
    width: 0;
    right: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 12vh 22vh ;
    border-color: transparent transparent #D7D8DD transparent;
}
.Tesim .trojuholnikVpravoVzadu{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 12.2vh 22.2vh;
    border-color: transparent transparent #B1B0B5 transparent;
}
.Tesim .lineVlavoVpredu{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.8vh 7.8vh 0;
    border-color: transparent transparent #EAE0D9 transparent;
}
.Tesim .lineVlavoVzadu{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8vh 8vh 0;
    border-color: transparent transparent #CAB59A transparent;
}
@media (max-width: 485px){
    .Tesim .logo{
        width:224px;
        height: 72.8px;
    }
}
@media (max-width: 345px){
    .Tesim .logo{
        width:156.8px;
        height: 50.96px;
    }
}
@media (min-width: 2686px){
    .Tesim .trojuholnikVpravoVpredu{
        border-width: 0 0 6vw 22vw ;
    }
    .Tesim .trojuholnikVpravoVzadu{
        border-width: 0 0 6.2vh 22.2vh;
    }
}
@media (min-width: 1600px){
    .Tesim {
        max-width: 1610px;
        margin: auto;
    }
}
@media (min-width: 1600px){
    .Tesim {
        max-width: 1610px;
        margin: auto;
    }
}
@media (min-height: 2000px){
    .Tesim .trojuholnikVpravoVpredu{
        border-width: 0 0 3vw 11vw ;
    }
    .Tesim .trojuholnikVpravoVzadu{
        border-width: 0 0 3.2vh 11.2vh;
    }
    .Tesim .lineVlavoVpredu{
        border-width: 0 3.8vh 3.8vh 0;
    }
    .Tesim .lineVlavoVzadu{
        border-width: 0 4vh 4vh 0;
    }
}