.ScrollToTop{
    background-color: #CAB59A;
    padding: 0.5rem;
    border-radius: 0.5rem;
    position: fixed;
    bottom:1.5rem;
    right: 1.5rem;
    z-index: 20;
    box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.3);
    animation: scrollTopAnimation 0.6s alternate ease infinite;
    transition: 0.2s;
}
.ScrollToTop:hover{
    background-color: #203C57;
    padding: 0.5625rem;
    position: fixed;
    bottom:1.5rem;
    right: 1.5rem;
    z-index: 20;
    box-shadow: 0rem 0.4375rem 0.4375rem rgba(0, 0, 0, 0.3);
}
.ScrollToTop img{
    width:1.875rem;
    height:auto;
    transition: 0.2s;
}
@keyframes scrollTopAnimation {
    from{
        transform: translateY(0.125rem);
    } to {
        transform: translateY(-0.0625rem);
    }
}
.sipkaDole{
    position: absolute;
    bottom: 0%;
    left:50%;
    z-index: 10;
    animation: scrollBottomAnimation 0.6s alternate ease infinite;
    cursor: pointer;
}
.sipkaDole img{
    width: 4.625rem;
    height: auto;
    transition: 0.5s;
}
.sipkaDole .sipkaDoleImg{
    filter:drop-shadow(0px 7px 7px rgba(0, 0, 0,0.3))
}
.sipkaDole .sipkaDoleImg:hover{
    transform: scale(1.1);
    color: #203C57 !important;
}
@keyframes scrollBottomAnimation {
    from{
        transform: translate(-50%, 0.125rem);
    } to {
        transform: translate(-50%, -0.0625rem);
    }
}
@media (max-width: 700px) or (max-height:670px){
    .sipkaDole{
        visibility: hidden;
    }
}
@media (max-height: 490px){
    .sipkaDole{
        visibility: hidden;
    }
}