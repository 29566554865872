.Delic .content{
    background-color: #EAE0D9;
}
.Delic .content svg{
    width: 100%;
    display: block;
    margin: auto;
}
@media (min-width: 1600px){
    .Omne .content{
        max-width: 1610px;
        margin: auto;
    }
    .Delic .content svg{
        max-width: 1610px;   
    }
}