@import url('https://fonts.googleapis.com/css2?family=Croissant+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sarabun:wght@500&display=swap');
.Uvod .Wrapper{
    position:relative;
}
.Uvod .content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    height: 100vh; 
    min-height: 500px;
    padding: 0px 6%;
}
.Uvod .div1 h2{
    color: #3B4A6B;
    font-weight: 500;
    font-style: italic;
    font-family: 'Sarabun', sans-serif;
}
.Uvod .trojuholnikVlavoHoreVpredu{
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10vw 20vw 0 0;
    border-color: #9AA7B9 transparent transparent transparent;
}
.Uvod .trojuholnikVlavoHoreVzadu{
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.2vw 20.2vw 0 0;
    border-color: #3B4A6B transparent transparent transparent;
}
.Uvod .lineVpravoVpredu{
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.8vw 7.8vw 0;
    border-color: transparent #EAE0D9 transparent transparent;
}
.Uvod .lineVpravoVzadu{
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8vw 8vw 0;
    border-color: transparent #CAB59A transparent transparent;
}
.Uvod .logo{
    width: min(50vw,1000px);
    position: relative;
    z-index: 1;
    /* width: calc(90vw); */
    /* height: calc((45*(550/1686))vw); */
}
.Uvod .div2{
    color: #CAB59A;
    width: 100%;
}
.Uvod .div2 h1{
    font-weight: 400;
    font-family: 'Croissant One', cursive;
    text-shadow: -2px 5px 10px rgba(255, 255, 255, 0.4);
}
.Uvod .div2 h1:nth-child(1){
    text-align: left;
}
.Uvod .div2 h1:nth-child(2){
    text-align: right;
}
.Uvod .content {
    background-color: #EAE0D9;
}
@media (max-width: 700px) AND (orientation: portrait){
    .Uvod .logo{
        width: 75vw;
    }

}
@media (min-width: 1600px){
    .Uvod .Wrapper{
        max-width: 1610px;
        margin: auto;
    }
}
@media (min-width: 2686px){
    .Uvod .trojuholnikVlavoHoreVpredu{
        border-width: 6vw 22vw 0 0;
    }
    .Uvod .trojuholnikVlavoHoreVzadu{
        border-width: 6.2vw 22.2vw 0 0;
    }
}
@media (min-width: 1600px){
    .Uvod {
        max-width: 1610px;
        margin: auto;
    }
}
@media (min-height: 2000px){
    .Uvod .trojuholnikVlavoHoreVpredu{
        border-width: 3vw 11vw 0 0;
    }
    .Uvod .trojuholnikVlavoHoreVzadu{
        border-width: 3.2vw 11.2vw 0 0;
    }
    .Uvod .lineVpravoVpredu{
        border-width: 0 3.8vw 3.8vw 0;
    }
    .Uvod .lineVpravoVzadu{
        border-width: 0 4vw 4vw 0;
    }
}