@import url('https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap');
.PopisSluzieb .content {
    padding: 0px 6%;
}
.PopisSluzieb .content h1 {
    color: #9AA7B9;
    font-family: 'Sarala', sans-serif;
    font-style: normal;
    font-weight: 700;
}
.PopisSluzieb .content p, li{
    font-family: 'Sarala';
    font-style: normal;
    color: #3B4A6B;
    line-height: 35px;
}
.PopisSluzieb .content {
    background-color: #EAE0D9;
}
.PopisSluzieb ul{
    list-style-type: circle;
    list-style-position: inside;
}
@media (min-width: 1600px){
    .PopisSluzieb .content{
        max-width: 1610px;
        margin: auto;
    }
}