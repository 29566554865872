@import url('https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap');
.Objednanie .content {
    background-color: #EAE0D9;
    padding: 0px 6%;
}
.Objednanie h1{
    color: #3B4A6B;
    font-family: 'Sarala', sans-serif;
    font-style: normal;
    font-weight: 700;
}
.Objednanie .podNadpisom{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Objednanie .podNadpisom .divHlavny{
    color:#3B4A6B;
    font-family: 'Sarala', sans-serif;
    font-style: normal;
    font-size: 20px;
    font-weight: 400;
}
.Objednanie .podNadpisom .ObjdnatSaMozete{
    margin-bottom: 40px;
}
.Objednanie .mailIcon{
    width: 25px;
    margin-right: 10px;
}
.Objednanie .phoneIcon{
    width: 25px;
    margin-right: 10px;
}
.Objednanie .exclamationIcon{
    height: 45px;
    margin-right: 15px;
    margin-left: 15px;
}
.Objednanie .percentIcon{
    height: 45px;
    margin-right: 15px;
    margin-left: 15px;
}
.Objednanie .podNadpisom .divHlavny .divMalyVlavo{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;   
    margin-bottom: 10px;
}
.Objednanie .podNadpisom .divHlavny .divMalyVpravo{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
@media (max-width: 1150px){
    .Objednanie .podNadpisom{
        margin-top: 30px;
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
    }
    .Objednanie .podNadpisom .divHlavny .divMalyVlavo{
        justify-content: center;   
        align-items: flex-start;
    }  
    .Objednanie .podNadpisom .ObjdnatSaMozete{
        margin-bottom: 30px;
    }
    .Objednanie .podNadpisom .divHlavny .divMalyVpravo{
        justify-content: center;
        margin-top: 10px;
    }
    .Objednanie .exclamationIcon:first-child{
        margin-left: 0px;
    }
    .Objednanie .exclamationIcon:last-child{
        margin-right: 0px;
    }
    .Objednanie .percentIcon:first-child{
        margin-left: 0px;
    }
    .Objednanie .percentIcon:last-child{
        margin-right: 0px;
    }
    .Objednanie .podNadpisom .divHlavny .divMalyVlavo p{
        text-align: left;
    } 
}
@media (max-width: 620px){
    .Objednanie .podNadpisom .divHlavny .divMalyVlavo p{
        max-width: 350px;
    } 
}
@media (max-width: 500px){
    .Objednanie .exclamationIcon{
        height: 30px;
        margin-right: 10px;
        margin-left: 10px;
    }
    .Objednanie .percentIcon{
        height: 30px;
        margin-right: 5px;
        margin-left: 5px;
    }
}
@media (min-width: 1600px){
    .Objednanie .content{
        max-width: 1610px;
        margin: auto;
    }
}
