@import url('https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap');
.Omne .content{
    padding: 0px 6%;
}
.Omne .podNadpisom{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Omne p, .Omne li {
    color: #3B4A6B;
}
.Omne .podNadpisom .podNadpisomVlavo{
    line-height: 35px;
    margin-right: 20px;
}
.Omne h1{
    color: #3B4A6B;
    font-family: 'Sarala', sans-serif;
    font-style: normal;
    font-weight: 700;
}
.Omne .content {
    background-color: #EAE0D9;
}
.Omne ol{
    list-style-type: decimal;
    list-style-position: inside;
}
.Omne img{
    width: clamp(23rem, 28vw, 26.25rem);
    height: 100%;
}
@media (max-width: 820px){
    .Omne .podNadpisom{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .Omne .podNadpisom .podNadpisomVlavo{
        margin-bottom: 20px;
    }
    .Omne p {
        text-align: justify;
    }
    .Omne img{
        width: min(23.75rem, 70vw);
    }
}
@media (min-width: 1600px){
    .Omne .content{
        max-width: 1610px;
        margin: auto;
    }
}