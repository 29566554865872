@import url('https://fonts.googleapis.com/css2?family=Sarala:wght@400;700&display=swap');
.Vzdelanie .content {
    background-color: #EAE0D9;
    padding: 0px 6%;
}
.Vzdelanie .podNadpisom{
    display: flex;
    flex-wrap: wrap;
}
.Vzdelanie .podNadpisom div{
    flex: 50%;  
    font-family: 'Sarala';
    font-style: normal;
    color: #3B4A6B;
}
.Vzdelanie .podNadpisom div p{
    margin-bottom: 28px;
    margin-right: 25px;
    min-width: 250px;
}
.Vzdelanie .podNadpisom div h2{
    margin-top: 28px;
    margin-bottom: 28px;
}
.Vzdelanie .content h1{
    color: #9AA7B9;
    font-family: 'Sarala', sans-serif;
    font-style: normal;
    font-weight: 700;
}
@media (min-width: 1600px){
    .Vzdelanie .content{
        max-width: 1610px;
        margin: auto;
    }
}